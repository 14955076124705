import React, { useState, useEffect, Suspense, lazy } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ClipboardJS from 'clipboard'
import scrollReveal from 'scrollreveal'
import './assets/css/main.css'

// Dynamically import components
const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('./Footer'));
const Index = lazy(() => import('./Index'));
const Blog = lazy(() => import('./pages/Blog'));
const Solutions = lazy(() => import('./pages/Solutions'));
const Admin = lazy(() => import('./pages/admin'));
const Article = lazy(() => import('./pages/Article'));

const App = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('ServiceWorker registered successfully.');
          registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                setIsUpdateAvailable(true);
              }
            });
          });
        })
        .catch(error => console.error('Error during service worker registration:', error));

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (isUpdating) {
          console.log('Updated service worker is now controlling the page. Reloading...');
          window.location.reload();
        }
      });
    }

    window.ClipboardJS = ClipboardJS;

    // Initialize ScrollReveal
    const sr = scrollReveal({
      origin: 'bottom',
      distance: '20px',
      duration: 800,
      delay: 200,
      easing: 'cubic-bezier(0.5, 0, 0, 1)',
      interval: 100,
      scale: 1,
      viewFactor: 0.2,
    });

    // Apply ScrollReveal to specific elements
    sr.reveal('.reveal-item', { delay: 200, distance: '20px', origin: 'bottom' });

    // Listen for the `beforeinstallprompt` Event
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Cleanup function
    return () => {
      if (sr && typeof sr.destroy === 'function') {
        sr.destroy();
      }
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [isUpdating]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    }
  };

  const handleUpdate = () => {
    if (isUpdateAvailable && !isUpdating) {
      setIsUpdating(true);
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="
          default-src 'self';
          script-src 'self' https://www.clique8.com https://c8-blogs.kitz.workers.dev https://cdnjs.cloudflare.com https://cdn.jsdelivr.net 'unsafe-eval' blob:;
          worker-src 'self' blob:;
          img-src 'self' https: data: blob:;
          media-src 'self' https://bucket.clique8.com;
          connect-src 'self' https://www.clique8.com https://c8-blogs.kitz.workers.dev https://c8-email.kitz.workers.dev https://cdn.jsdelivr.net/;
          style-src 'self' 'unsafe-inline' https://cdnjs.cloudflare.com https://cdn.jsdelivr.net https://fonts.googleapis.com;
          font-src 'self' https://cdn.jsdelivr.net https://fonts.gstatic.com;
        "></meta>
      </Helmet>
      <Suspense fallback={<div className="w-full h-screen flex items-center justify-center">Loading...</div>}>
        <Header isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} />
        <main className="pt-16">
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/article/:slug" element={<Article />} />
          </Routes>
        </main>
        {showInstallButton && (
          <button onClick={handleInstallClick} className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg">
            Install Clique8 App
          </button>
        )}
        {isUpdateAvailable && !isUpdating && (
          <button onClick={handleUpdate} className="fixed bottom-4 left-4 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg">
            Update Available
          </button>
        )}
        {isUpdating && (
          <div className="fixed bottom-4 left-4 bg-yellow-500 text-white px-4 py-2 rounded-lg shadow-lg">
            Updating...
          </div>
        )}
        <Footer />
      </Suspense>
    </>
  )
}

export default App
